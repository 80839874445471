<template>
  <div class="home">
    <x-header>科研智能体</x-header>

    <x-content
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <x-card>
        <el-row :gutter="20" class="researchTools">
          <el-col :span="8" v-for="(item,index) in toolList" :key="index">
            <div class="item">
              <a :href="item.url" target="_blank">
                <el-row :gutter="20">
                  <el-col :xl="20" :md="17">
                    <h3>{{item.title}}</h3>
                    <p class="tags">
                      <el-tag size="small" v-for="tag in item.tags" :key="tag">{{tag}}</el-tag>
                    </p>
                  </el-col>
                  <el-col :xl="4" :md="7" class="rightImg">
                    <img  :src="item.icon" class="icon">
                    </el-col>
                  <el-col :span="24">
                    <p class="desc">{{ item.desc }}</p>
                  </el-col>
                </el-row>
              </a>
            </div>
          </el-col>
        </el-row>
      </x-card>
    </x-content>
  </div>
</template>
<script>
import XCard from "../../components/busiess/XCard.vue";
export default {
  components: { XCard },
  name: "ApplyUseManager",
  data() {
    return {
      loading: false,
      toolList:[
        {
        title:'公式识别',
        icon:'/static/img/latexOcr_img.png',
        url:'/manager/researchTools/latexOcr',
        tags:['自动识别','快捷准确'],
        desc:'自动识别图片中的公式，并将其转换为LaTeX格式的字符串。该功能支持分析扫描的图片、手写公式，以及包含中英文文字的混合公式。'
        },
        {
        title:'化学结构式识别',
        icon:'/static/img/chemistryOcr_img.png',
        url:'/manager/researchTools/chemistryOcr',
        tags:['化学图像识别','图像数据挖掘'],
        desc:'基于深度学习的工具，旨在自动识别化学结构图像。将化学图像转换为SMILES 字符串，支持在不同化学编辑器之间复制和粘贴结构。'
        },
        {
        title:'文档提取',
        icon:'/static/img/documentExtraction_img.png',
        url:'/manager/researchTools/documentExtraction',
        tags:['精准识别','内容转换','公式转换'],
        desc:'精准识别PDF中的图像、公式、表格及文本等多元内容，并将其高效转换为Markdown格式或JSON格式；对于数学公式，能够准确识别并转换为 LaTeX 格式，确保公式的专业呈现与精准表达。'
        },
        {
        title:'实验百事通',
        icon:'/static/img/chat_img.png',
        url:'/manager/researchTools/chat',
        tags:['快速精准','在线解答'],
        desc:'实验百事通，在线精准解答实验难题，让你轻松应对实验挑战。'
        },
        {
        title:'语音听写',
        icon:'/static/img/speechRecognition_img.png',
        url:'/manager/researchTools/speechRecognition',
        tags:['高准确率','多语种','毫秒级'],
        desc:'用于1分钟内的即时语音转文字技术，实时返回识别结果，达到一边上传音频一边获得识别文本的效果。'
        },
        {
        title:'星火科研助手',
        icon:'/static/img/sciai_img.png',
        url:'https://sciai.las.ac.cn/',
        tags:['成果调研','论文研读','学术写作'],
        desc:'基于科技文献大模型2.0，集成果调研、论文研读、学术写作、科研智能体中心于一体，为科研工作者量身打造高效精准的科技文献服务体系，全面加速学术研究与科研进程。'
        },
        {
        title:'文献获取',
        icon:'/static/img/database_img.png',
        url:'https://www.stpaper.cn/microapp/widget/tools/database',
        tags:['双认证体系','非指定IP范围'],
        desc:'基于国际Internet2.0的Shibboleth协议，依托中国科技云CSTCloudAAI认证联盟，通过个人账号漫游身份认证的方式，实现科研人员居家办公、差旅外出等环境下，随时随地访问所在机构订购的数据平台资源。'
        },
        {
        title:'科研差旅',
        icon:'/static/img/business_img.png',
        url:'https://www.stpaper.cn/microapp/widget/tools/business',
        tags:['差旅费标准','即时查询'],
        desc:'各级国家机关、事业单位及企业人员差可实时在线查询最新差旅费标准。'
        },
      ]
    };
  },
  filters: {},
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.researchTools .item {
  background-color: #fff;
  background-image: linear-gradient( #fff,#e8f3ff);
  line-height: 2;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 220px;
}
.researchTools .item:hover{
  background-image: linear-gradient(#9cecfb,#e8f3ff,#ffffff);

}
.researchTools {
  margin-top: 20px;
}
.researchTools .item h3 {
  font-size: 20px;
  color: #1441c2;
  font-weight: bold;
  margin-bottom: 10px;
}
.researchTools .item p {
  text-align: left;
  margin: 10px 0px;
}

.researchTools .item .desc {
  height: 84px;
  word-break: break-all;
  -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 28px;
    display: -webkit-box;
}
.researchTools .item p .base-tag {
  margin-right: 5px;
  /**   color: #409eff;
    background-color: #ecf5ff;
    border: 1px solid #d9ecff;
    */
}
.researchTools .item .icon {
  vertical-align: bottom;
  width: 100%;
}
@media screen and (max-width: 1366px) {
  .researchTools .item .rightImg {
    margin-top: 3%;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  .researchTools .item {
  }
  .researchTools .item .rightImg {
    margin-top: 0%;
  }
}
@media screen and (min-width: 1367px) and (max-width: 1599px) {
  .researchTools .item {
  }

}
@media(min-width: 1601px) and (max-width: 1919px) {
  .researchTools .item {
    height: 210px;
  }

}
@media(min-width: 1920px){
  .researchTools .item {
    height: 190px;
  }
}

</style>
